// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

document.addEventListener('DOMContentLoaded', function () {
  if (window.Bus) {
    // listen to events here
  }

  console.log('Checking pipeline - third time');
});
