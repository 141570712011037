const _temp0 = require("./components/accordion/_accordion.scss");

const _temp1 = require("./components/button/_button.scss");

const _temp2 = require("./components/container-isi/_container-isi.scss");

const _temp3 = require("./components/footer/_footer.scss");

const _temp4 = require("./components/header/_header.scss");
const _temp5 = require("./components/header/_nav.scss");

const _temp6 = require("./components/modal/_modal.scss");

const _temp7 = require("./components/teaser/_teaser.scss");

const _temp8 = require("./components/text/_text.scss");

module.exports = {
  "accordion":   {
    "_accordion": _temp0
  },
  "button":   {
    "_button": _temp1
  },
  "container-isi":   {
    "_container-isi": _temp2
  },
  "footer":   {
    "_footer": _temp3
  },
  "header":   {
    "_header": _temp4,
    "_nav": _temp5
  },
  "modal":   {
    "_modal": _temp6
  },
  "teaser":   {
    "_teaser": _temp7
  },
  "text":   {
    "_text": _temp8
  }
}